body {
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Inter'
}
input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.tagify__dropdown__wrapper {
  border: none !important;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.18);
}
.ql-tooltip {
  height: 0 !important;
}